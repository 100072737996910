import {Modals} from "./CommonTypes";
import {marked} from "marked";
import DOMPurify from "dompurify";
import * as CryptoJS from 'crypto-js';


export const closeAndDispatchModal = (modalId: Modals) => {
    const event = new CustomEvent('closeModal', {
        detail: { modalId, closed: true }
    });
    document.dispatchEvent(event);
}

export const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
};

export const markdownToPlainText = (markdownContent: string) => {
    // Step 1: Convert Markdown to HTML
    const rawHtml = marked(markdownContent);

    // Step 2: Sanitize the HTML content
    const sanitizedHtml = DOMPurify.sanitize(rawHtml as string);

    // Step 3: Remove HTML tags to get plain text
    return sanitizedHtml.replace(/<\/?[^>]+(>|$)/g, "");
}

export function generateUniqueName(filename: string): string {
    const hash = CryptoJS.SHA256(filename);
    return hash.toString(CryptoJS.enc.Hex).slice(0, 20);
}

export const transformImagePath = (src: string, template: string): string => {
    const parts = src.split('/');
    const uniqName = (generateUniqueName(parts[parts.length - 1]));
    parts.pop();
    const dirPath = parts.join('/');
    return `${dirPath}/${template.replace('{filename}', uniqName)}.jpg`;
};