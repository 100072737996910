import {Fragment, h} from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import { format } from 'date-fns';
import styles from './Calendar.module.sass';
import { CalendarGridItemProps } from './Types';

const CalendarGridItem = ({ day, dayEvents, isCurrentDay }: CalendarGridItemProps) => {
    const [showScroll, setShowScroll] = useState<boolean>(false);
    const overlayRef = useRef<HTMLDivElement>(null);

    const checkScroll = () => {
        if (!overlayRef.current) return;

        const { scrollHeight, clientHeight } = overlayRef.current;
        // Determine if the content overflows and thus a scrollbar is visible
        const isOverflowing = scrollHeight > clientHeight;

        // Update showScroll state based on content overflow
        setShowScroll(isOverflowing);
    };

    useEffect(() => {
        const overlay = overlayRef.current;
        if (overlay) {
            overlay.addEventListener('scroll', checkScroll);
            // Perform an initial check to update controls upon mounting
            checkScroll();
        }

        return () => overlay?.removeEventListener('scroll', checkScroll);
    }, []); // Dependency array left empty to run once on mount

    return (
        <div className={`${styles.calendarGridItem} ${isCurrentDay ? styles.currentDay : ''}`}>
            <div className={styles.calendarGridItemContent}>{day.day}</div>
            {dayEvents.length > 0 && (
                <div className={styles.calendarGridItemOverlay}>
                    {showScroll && (
                        <Fragment>
                            <div className={styles.scrollUp}></div>
                            <div className={styles.scrollDown}></div>
                        </Fragment>
                    )}
                    <div className={styles.calendarGridItemOverlayInner} ref={overlayRef}>
                        <div className={styles.weekDay}>
                            <a href={`https://sof.advantagebooking.com/book/clinic/${format(day.date, 'MM-dd-yyyy')}`}>{day.day}</a>
                        </div>
                        {dayEvents.map((event) => (
                            <a href={`https://sof.advantagebooking.com/book/clinic/${format(event.start_date, 'MM-dd-yyyy')}#dialogs=sessioninfo/calendar/${event.source_id}`}
                               key={event.id}>
                                <div className={styles.sessionTitle}>{event.title}</div>
                                <div className={styles.sessionTime}>
                                    {`${event.start_date.getHours()}:${event.start_date.getMinutes().toString().padStart(2, '0')}-${event.end_date.getHours()}:${event.end_date.getMinutes().toString().padStart(2, '0')}`}
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalendarGridItem;
