import { FunctionComponent, h } from 'preact';
import styles from './Summary.module.sass';
import {useState} from "preact/hooks";
import {ISummary} from "../../CommonTypes";

interface SummaryProps {
    summary: ISummary;
    color: 'Red' | 'Black';
    onToggle: () => void;
}

const Summary: FunctionComponent<SummaryProps> = ({ summary, color, onToggle }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        onToggle();
    };

    return (
        <div className={styles['summary' + color]}>
            <div className={styles.desktop}>
                <div className={styles.summaryType}>{summary.title}</div>
                <div className={styles.summaryWrapper} style={{flexGrow: 1}}>
                    <div>
                        <div className={styles.from}>From</div>
                        <div className={styles.priceFrom}>{summary.priceFrom}</div>
                    </div>
                    <ul className={styles.description}>
                        {summary.description.map((desc, index) => (
                            <li key={index}>{desc}</li>
                        ))}
                    </ul>
                </div>
                <button className="button-transparent">
                    <a href={summary.link} target="_blank">Book Now</a>
                </button>
            </div>
            <div className={styles.mobile}>
                <div className={styles.summaryWrapper}>
                    <div className={styles.content}>
                    <div className={styles.summaryType}>{summary.title}</div>
                        <div className={styles.from}>From</div>
                        <div className={styles.priceFrom}>{summary.priceFrom}</div>
                    </div>
                    <div
                        className={`${styles.expand} ${isExpanded ? styles.expanded : ''}`}
                        onClick={toggleExpand}
                    ></div>
                </div>

                    <div className={`${styles.expandableContent} ${isExpanded ? styles.expandableContentExpanded : ''}`}>
                        <ul className={styles.description}>
                            {summary.description.map((desc, index) => (
                                <li key={index}>{desc}</li>
                            ))}
                        </ul>
                        <button className="button-transparent">
                            <a href={summary.link} target="_blank">Book Now</a>
                        </button>
                    </div>

            </div>


        </div>
    );
};

export default Summary;
