import { h, FunctionalComponent } from 'preact';
import register from 'preact-custom-element';
import Modal from "../modal/Modal";
import {closeAndDispatchModal} from "../utils";
import FeedbackForm from "./FeedbackForm";

interface Props {
    visible: string;
}

const Feedback: FunctionalComponent<Props> = ({ visible }) => {
    const handleClose = () => closeAndDispatchModal('feedbackForm');

    return visible === 'true' ? (
        <Modal onClose={handleClose} maxWidth={700}>
            <FeedbackForm />
        </Modal>
    ) : null;
};

register(Feedback, 'sof-feedback', ['visible']);
