import {h} from 'preact';
import {useState, useEffect, useRef} from 'preact/hooks';
import register from 'preact-custom-element';
import styles from './Carousel.module.sass';
import {transformImagePath} from "../utils";

interface Slide {
    link: string;
    title: string;
    image: string;
    video?: string;
    order: number;
    text1?: string;
    text2?: string;
}

const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const slideIntervalDuration = 10000;
    const slideIntervalRef = useRef<number | null>(null);
    const [slides, setSlides] = useState<Slide[]>([]);

    useEffect(() => {
        fetch("/data/carousel.json")
            .then(res => res.json())
            .then((data: Slide[]) => setSlides((data).sort((a, b) => a.order - b.order)));
    }, []);

    useEffect(() => {
        if (slides.length > 1) {
            slideIntervalRef.current = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
            }, slideIntervalDuration);
        }

        return () => {
            if (slideIntervalRef.current) clearInterval(slideIntervalRef.current);
        };
    }, [slides.length]);

    const goToImage = (index: number) => {
        setCurrentIndex(index);
        if (slideIntervalRef.current) clearInterval(slideIntervalRef.current);
    };

    return (
        <div className={styles.carousel}>
            {slides.map((slide, index) => (
                <div key={index} className={`${styles.slide } ${styles.preloaderImage} ${index === currentIndex ? styles.active : ''}`}  style={{backgroundImage: `url('${transformImagePath(slide.image, 'optimized/{filename}')}')`}}>
                    {slide.video ? (
                        <a className={styles.link} href={slide.link} target="_blank">
                            <video className={styles.slideInner} autoPlay loop muted playsInline>
                                <source src={slide.video} type="video/mp4"/>
                            </video>
                        </a>
                    ) : (
                        <div style={{backgroundImage: `url('${transformImagePath(slide.image, 'optimized/{filename}')}')`}} className={styles.slideInner}>
                            <a className={styles.link} href={slide.link} target="_blank"></a>
                        </div>
                    )}
                    {(slide.text1 || slide.text2) && (
                        <a className={`${styles.link} ${styles.textWrapper}`} href={slide.link} target="_blank">
                            {slide.text1 && <div className={styles.redText} data-aos="fade-right"
                                                 data-aos-delay="1000">{slide.text1}</div>}
                            {slide.text2 && <div className={styles.blackText} data-aos="fade-left"
                                                 data-aos-delay="1000">{slide.text2}</div>}
                        </a>
                    )}
                </div>
            ))}
            <span className={styles.arrowLeft}
                  onClick={() => goToImage((currentIndex - 1 + slides.length) % slides.length)}></span>
            <span className={styles.arrowRight} onClick={() => goToImage((currentIndex + 1) % slides.length)}></span>
            <div className={styles.indicators}>
                {slides.map((_, index) => (
                    <span key={index} className={`${styles.indicator} ${index === currentIndex ? styles.active : ''}`}
                          onClick={() => goToImage(index)}></span>
                ))}
            </div>
        </div>
    );
};

register(Carousel, 'sof-carousel', ['images']);
