import { FunctionComponent, h } from 'preact';
import register from 'preact-custom-element';
import { LessonOrEvent } from '../CommonTypes';
import styles from './Pricing.module.sass';
import Summary from "./Summary/Summary";
import Table from "./Table/Table";
import {useState} from "preact/hooks";

const Pricing: FunctionComponent = () => {

    // Toggle function to update the state
    const toggleTableVisibility = (index: number) => {
        setExpandedIndices(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    const jsonData = document.getElementById('pricing-data')!.textContent as string;
    const data: LessonOrEvent[] = JSON.parse(jsonData);
    // State to track the visibility of the Table for each item
    const [expandedIndices, setExpandedIndices] = useState<boolean[]>(data.map(() => false));
    return (
        <div className={styles.pricingContainer}>
            {data.map((item, index) => (
                <div key={index} className={index % 2 === 0 ? styles.redWrapper : styles.blackWrapper}>
                    <div className={styles.pricingSection + ' content-wrapper'}>
                        <Summary summary={item.summary}
                                 onToggle={() => toggleTableVisibility(index)}
                                 color={index % 2 === 0 ? 'Red' : 'Black'} />
                        <div className={styles.mobile}>
                        {expandedIndices[index] && (
                        <Table data={{
                            headers: item.headers,
                            pricingByType: item.pricingByType,
                            remark: item.remark
                        }} />
                        )}
                        </div>
                        <div className={styles.desktop}>
                            <Table data={{
                                headers: item.headers,
                                pricingByType: item.pricingByType,
                                remark: item.remark
                            }} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

register(Pricing, 'sof-pricing');
