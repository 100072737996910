import { h, FunctionalComponent } from 'preact';
import register from 'preact-custom-element';
import SubscriptionForm from './SubscriptionForm';
import Modal from "../modal/Modal";
import {closeAndDispatchModal} from "../utils";

interface SubscriptionFormPopupProps {
    visible: string; // Since attributes are always strings, we use string type here
}

const NewsLetter: FunctionalComponent<SubscriptionFormPopupProps> = ({ visible }) => {
    const handleClose = () => closeAndDispatchModal('subscriptionForm');

    // Directly conditionally render the Modal based on the visible prop
    return visible === 'true' ? (
        <Modal onClose={handleClose} maxWidth={500}>
            <SubscriptionForm />
        </Modal>
    ) : null;
};

register(NewsLetter, 'sof-newsletter', ['visible']);
