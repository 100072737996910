import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import register from 'preact-custom-element';

interface CounterComponentProps {
    count?: string;
}

const CounterComponent = ({ count: initialCount = '0' }: CounterComponentProps) => {
    const [count, setCount] = useState<number>(parseInt(initialCount, 10));

    useEffect(() => {
        setCount(parseInt(initialCount, 10));
    }, [initialCount]);

    const increment = () => setCount(c => c + 1);
    const decrement = () => setCount(c => c - 1);

    return (
        <div>
            <p>Count: {count}</p>
            <button onClick={increment}>+</button>
            <button onClick={decrement}>-</button>
        </div>
    );
};

register(CounterComponent, 'x-counter', ['count'], { shadow: true });
