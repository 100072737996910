import { h } from 'preact';
import { format } from 'date-fns';
import { useState } from 'preact/hooks'; // Import useState from Preact hooks
import styles from './CalendarMobile.module.sass';
import { CalendarGridItemProps } from './Types';

const CalendarGridItemMobile = ({ day, dayEvents, isCurrentDay }: CalendarGridItemProps) => {
    // State to track if the grid item is open or closed
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle the open state
    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className={`${styles.gridItem} ${isOpen ? styles.open : ''}`}>
            <div className={`${styles.weekDayCell} ${isCurrentDay ? styles.currentDay : ''}`}>
                <a href={`https://sof.advantagebooking.com/book/clinic/${format(day.date, 'MM-dd-yyyy')}`}>
                    <div className={styles.day}>{format(day.date, 'dd')}</div>
                    <div className={styles.weekDay}>{day.day}</div>
                </a>
            </div>

            <div className={styles.dayEvents}>
                {dayEvents.map((event) => (
                    <div className={styles.eventItem} key={event.id}>
                        <a href={`https://sof.advantagebooking.com/book/clinic/${format(event.start_date, 'MM-dd-yyyy')}#dialogs=sessioninfo/calendar/${event.source_id}`}>
                            <span className={styles.sessionTitle}>{event.title}</span>
                            <span className={styles.sessionTime}>
                                {`${event.start_date.getHours()}:${event.start_date.getMinutes().toString().padStart(2, '0')}-${event.end_date.getHours()}:${event.end_date.getMinutes().toString().padStart(2, '0')}`}
                            </span>
                        </a>
                    </div>
                ))}
            </div>

            {dayEvents.length > 1 && (
                <span className={`${styles.arrow} ${isOpen ? styles.openArrow : ''}`} onClick={toggleOpen}></span>
            )}
        </div>
    );
};

export default CalendarGridItemMobile;
