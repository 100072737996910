import { h, FunctionComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import styles from './Modal.module.sass';

interface ModalProps {
    maxWidth?: number; // Optional maxWidth prop
    onClose: () => void;
    children?: h.JSX.Element | h.JSX.Element[];
}

const Modal: FunctionComponent<ModalProps> = ({ onClose, children, maxWidth }) => { // Default maxWidth to 550
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);

        // Disable scrolling on the main page
        document.body.style.overflow = 'hidden';

        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Escape' || e.key === 'Esc') {
                close();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.body.style.overflow = '';
        };
    }, []);

    const modalClass = isVisible ? `${styles.modal} ${styles.open}` : styles.modal;

    const close = () => {
        setIsVisible(false);
        setTimeout(onClose, 200);
    };

    const modalStyle = maxWidth ? {
        maxWidth: `${maxWidth}px`,
        //width: 'auto', // Allow width to grow with content
        margin: 'auto',
    } : {
        width: 'auto', // Ensure modal fits content when no maxWidth is provided
        margin: 'auto',
    };

    return (
        <div className={styles.backdrop} onClick={close}>
            <div className={modalClass} onClick={e => e.stopPropagation()} style={modalStyle}>
                <div className={styles.modalContentWrapper}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={close}>&times;</span>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
