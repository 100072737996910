import {FunctionComponent, h} from 'preact';
import {useEffect, useState} from 'preact/hooks';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import {PropsWithChildren} from "preact/compat";

const Markdown: FunctionComponent<PropsWithChildren<{}>> = ({children}) => {
    const [html, setHtml] = useState<string>('');

    useEffect(() => {
        // Assuming children is always a string for simplicity
        const content = children as string;
        if (content) {
            const rawMarkup = marked(content);
            const sanitizedMarkup = DOMPurify.sanitize(rawMarkup as string);
            setHtml(sanitizedMarkup);
        }
    }, [children]);

    return <div dangerouslySetInnerHTML={{__html: html}}/>;
};

export default Markdown;
