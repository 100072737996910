import {h, FunctionComponent} from 'preact';
import {useEffect, useRef, useState} from 'preact/hooks';
import './FeedbackForm.sass';
import {isValidEmail} from "../utils";

declare const grecaptcha: any;

interface FormState {
    fullName: string;
    email: string;
    message: string;
}

interface FormErrors {
    fullName: string | null;
    email: string | null;
    message: string | null;
    captcha: string | null;
}

const FeedbackForm: FunctionComponent = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, []);

    const [formState, setFormState] = useState<FormState>({
        fullName: '',
        email: '',
        message: ''
    });

    const siteKey = '6Ld6-HopAAAAAGROR76gRNyHs2N3zwLUCsEn1os8';
    const feedbackUrl = 'https://old.squashonfire.com/api/feedback';

    const [formErrors, setFormErrors] = useState<FormErrors>({
        fullName: null,
        email: null,
        message: null,
        captcha: null,
    });

    const fullNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => fullNameRef.current?.focus(), []);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e: h.JSX.TargetedEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = e.currentTarget;
        setFormState(prevState => ({...prevState, [name]: value}));
        setFormErrors(prevErrors => ({...prevErrors, [name]: null}));
    };

    const validateForm = () => {
        const errors: FormErrors = {
            fullName: formState.fullName ? null : 'First name is required',
            email: isValidEmail(formState.email) ? null : 'Email is invalid',
            message: formState.message ? null : 'Message is required',
            captcha: grecaptcha.getResponse() ? null : 'CAPTCHA is required to prove you are not a robot',
        };
        setFormErrors(errors);
        return !Object.values(errors).some(error => error !== null);
    };

    const handleSubmit = async (e: Event) => {
        e.preventDefault();
        if (!validateForm()) {
            return; // Stop the form submission if validation fails
        }

        // Retrieve the reCAPTCHA response
        const recaptchaResponse = grecaptcha.getResponse();

        // Prepare the data to be sent
        const formData = new FormData();
        formData.append('first_name', formState.fullName);
        formData.append('email', formState.email);
        formData.append('message', formState.message);
        formData.append('url', window.location.href);
        formData.append('g-recaptcha-response', recaptchaResponse);

        setIsSubmitting(true);

        try {
            const response = await fetch(feedbackUrl, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            if (data.success) {
                setIsSubmitted(true);
            } else {
                alert('Feedback submission failed. ' + data.message);
            }
        } catch (error) {
            console.error('Submission error:', error);
            alert('An error occurred while submitting the feedback.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="feedback-form">
            {isSubmitted ? (
                <div className="success-message">
                    <h2 className="section-header">Thank you</h2>
                    <div>Your message was sent successfully.</div>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2 className="section-header">Talk To Us</h2>
                    <div className="form-fields">
                        <div className="form-group1">
                            <div className="form-group">
                                <label htmlFor="fullName" className="form-label">Full Name</label>
                                <input
                                    type="text"
                                    id="fullName"
                                    ref={fullNameRef}
                                    name="fullName"
                                    className="form-input"
                                    value={formState.fullName}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                />
                                {formErrors.fullName && <div className="error-message">{formErrors.fullName}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-input"
                                    value={formState.email}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                />
                                {formErrors.email && <div className="error-message">{formErrors.email}</div>}
                            </div>
                        </div>
                        <div className="form-group2">
                            <div className="form-group">
                                <label htmlFor="message" className="form-label">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    className="form-input"
                                    value={formState.message}
                                    onChange={handleChange}
                                    rows={3}
                                    disabled={isSubmitting}
                                ></textarea>
                                {formErrors.message && <div className="error-message">{formErrors.message}</div>}
                            </div>
                        </div>
                    </div>
                    <div class="button-row">
                        <div className="form-group captcha">
                            <div className="g-recaptcha" data-sitekey={siteKey}></div>
                            {formErrors.captcha && <div className="error-message">{formErrors.captcha}</div>}
                        </div>
                        <button type="submit" className="button-black" disabled={isSubmitting}>
                            {isSubmitting ? (<span className="loading"><i className="fas fa-spinner fa-spin"></i></span>) : ''}
                            Send Message</button>
                    </div>
                </form>
            )}
        </div>
    );

};

export default FeedbackForm;