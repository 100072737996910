import { h } from 'preact';
import { useEffect, useState, useCallback } from 'preact/hooks';
import register from 'preact-custom-element';
import { CalendarDay, CalendarEvent } from "./Types";
import { CalendarDesktop } from "./CalendarDesktop";
import styles from "./Calendar.module.sass";
import { CalendarMobile } from "./CalendarMobile";
import { parse } from "date-fns";

const useFetchEvents = (apiUrl: string) => {
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [eventsLoaded, setEventsLoaded] = useState<boolean>(false);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                processData(data);
                setEventsLoaded(true);
            } catch (error) {
                console.error('Fetch operation error:', error);
                setEventsLoaded(false);
            }
        };
        fetchEvents();
    }, [apiUrl]);

    const processData = (data: CalendarEvent[]) => {
        const parseDate = (dateString: any) => parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
        const formattedEvents = data.map(event => ({
            ...event,
            start_date: parseDate(event.start_date),
            end_date: parseDate(event.end_date)
        }));
        setEvents(formattedEvents);
    };

    return { events, eventsLoaded };
};

const detectMobile = () => {
    const userAgent = navigator.userAgent;
    const isMobileDevice = /iPad|Macintosh|Android/.test(userAgent) && ('ontouchend' in document || /Android/.test(userAgent));
    const isScreenWidthSmall = window.innerWidth < 835;
    return isMobileDevice || isScreenWidthSmall;
};

const Calendar = () => {
    const apiUrl = 'https://old.squashonfire.com/api/calendar';
    const [currentWeek, setCurrentWeek] = useState<CalendarDay[]>([]);
    const [currentDayIndex] = useState<number>(new Date().getDay());
    const { events, eventsLoaded } = useFetchEvents(apiUrl);
    const [isMobile, setIsMobile] = useState<boolean>(detectMobile());

    useEffect(() => {
        generateCurrentWeek();

        // Listen for window resize to adjust isMobile state
        const handleResize = () => {
            setIsMobile(detectMobile());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const generateCurrentWeek = useCallback(() => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
        const weekDates: CalendarDay[] = Array.from({ length: 7 }).map((_, i) => {
            const dayDate = new Date(startOfWeek);
            dayDate.setDate(startOfWeek.getDate() + i);
            return { day: getDayName(dayDate), date: dayDate };
        });
        setCurrentWeek(weekDates);
    }, []);

    const getDayName = useCallback((date: Date): string => date.toLocaleDateString('en-US', { weekday: 'long' }), []);

    return (
        <div className={styles.calendar}>
            {isMobile ? (
                <div class={styles.ipad}>
                    <CalendarMobile currentWeek={currentWeek}
                                    eventsLoaded={eventsLoaded}
                                    events={events}
                                    currentDayIndex={currentDayIndex}></CalendarMobile>
                </div>
            ) : (
                <div class={styles.desktop}>
                    <CalendarDesktop currentWeek={currentWeek}
                                     eventsLoaded={eventsLoaded}
                                     events={events}
                                     currentDayIndex={currentDayIndex}></CalendarDesktop>
                </div>
            )}
        </div>
    );
};

register(Calendar, 'sof-calendar');
