import { h } from 'preact';
import styles from "./Calendar.module.sass";
import CalendarGridItem from "./CalendarGridItem";

import { CalendarDay, CalendarEvent } from "./Types"; // Assuming Types is the file where you define types/interfaces

interface Props {
    currentWeek: CalendarDay[];
    eventsLoaded: boolean;
    events: CalendarEvent[];
    currentDayIndex: number;
}

export const CalendarDesktop = ({ currentWeek, eventsLoaded, events, currentDayIndex }: Props) => {

    const generateWeekInterval = (): string => {
        if (currentWeek.length === 0) return '';
        const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
        const startDate = currentWeek[0].date;
        const endDate = currentWeek[currentWeek.length - 1].date;
        const startFormat = new Intl.DateTimeFormat('en-US', options).format(startDate);
        const endFormat = new Intl.DateTimeFormat('en-US', options).format(endDate);
        return `${startFormat} - ${endFormat}`;
    };

    return (
        <div className={styles.calendarGrid}>
            <div className={styles.calendarGridItem}>
                <div className={styles.calendarGridItemContent + ' ' + styles.calendarWeek}>
                    <div className={styles.weekTitle}>This Week</div>
                    <div className={styles.weekLine}></div>
                    <div className={styles.weekInterval}>{generateWeekInterval()}</div>
                </div>
            </div>
            {eventsLoaded && currentWeek.map((item, index) => {
                // Filter events for the current day
                const dayEvents = events.filter(event =>
                    new Date(event.start_date).toDateString() === item.date.toDateString()
                );
                return (
                    <CalendarGridItem
                        key={item.day}
                        day={item}
                        dayEvents={dayEvents}
                        isCurrentDay={index === currentDayIndex}
                    />
                );
            })}
        </div>
    );

};
