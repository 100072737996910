import { FunctionComponent, h } from 'preact';
import Modal from "../modal/Modal";
import Markdown from "../Markdown";
import { transformImagePath } from "../utils";
import { Person } from "../CommonTypes";
import styles from '../coaches/Coaches.module.sass';

interface CoachesModalProps {
    coach: Person;
    isVisible: boolean;
    onClose: () => void;
}

const CoachesModal: FunctionComponent<CoachesModalProps> = ({ coach, isVisible, onClose }) => {
    if (!isVisible || !coach) return null;
    return (
        <Modal onClose={onClose} maxWidth={1000}>
            <div>
                <div className={styles.contentWrapper}>
                    <div className={styles.pictureWrapper}>
                        <div className={styles.picture}
                             style={{backgroundImage: `url(${transformImagePath(coach.photo, 'preview/{filename}-red')})`}}></div>
                        <div>
                            <div className={styles.nameTitle}>
                                <div className={styles.name}>{coach.fullname}</div>
                                <div className={styles.title}>{coach.title}</div>
                            </div>
                            {coach.category !== 3 && (
                                <div className={styles.button}>
                                    <a target="_blank"
                                       href={`https://sof.advantagebooking.com/book/lesson/today/coach/${coach.id}`}>
                                        <button className={`button-transparent button-black`}>Book a Lesson</button>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <p><Markdown>{coach.description}</Markdown></p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CoachesModal;
