import { h, FunctionComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import register from 'preact-custom-element';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import styles from './Coaches.module.sass';

import 'swiper/css';
import 'swiper/css/navigation';
import {markdownToPlainText, transformImagePath} from "../utils";
import {Person} from "../CommonTypes";
import CoachesModal from "../coachesModal/CoachesModal";


const Coaches: FunctionComponent = () => {
    const [coaches, setCoaches] = useState<Person[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCoach, setSelectedCoach] = useState<Person | null>(null);

    const showCoachDetails = (coach: Person) => {
        setSelectedCoach(coach);
        setIsModalVisible(true);
    };

    useEffect(() => {
        fetch("/data/people.json").then(res => {
            res.json().then((data: Person[]) => {
                data.sort((a, b) => {
                    if (a.category < b.category) return -1;
                    if (a.category > b.category) return 1;
                    return a.order - b.order;
                });
                setCoaches(data.filter(coach => coach.category !== 3));
            });
        });
    }, []);

    return (
        <div className={styles.swiperWrapper}>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                breakpoints={{
                    580: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                }}
                modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                className={styles.swiper}
            >
                {coaches.map((coach) => (
                    <SwiperSlide className={styles.slide} key={coach.id}>
                        <div className={styles.slideInner}>
                                <a href="javascript:void(0);" onClick={() => showCoachDetails(coach)}>
                                    <img src={transformImagePath(coach.photo, 'preview/{filename}-black')} alt={coach.fullname}/>
                                </a>
                                <h2>{coach.fullname}</h2>
                                <h3>{coach.title}</h3>
                            <p>{cutTextByWord(markdownToPlainText(coach.description), 140)}</p>
                            <div className={styles.readMore}>
                                <a href="javascript:void(0);" onClick={() => showCoachDetails(coach)}>Read More &rarr;</a>
                            </div>
                            <div className={styles.buttonContainer}>
                                <a target="_blank" href={`https://sof.advantagebooking.com/book/lesson/today/coach/${coach.id}`}><button className={`button-transparent ${styles.buttonWhite}`}>Book a Lesson</button></a>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={`${styles.swiperButtonPrev} swiper-button-prev`}></div>
            <div className={`${styles.swiperButtonNext} swiper-button-next`}></div>
            <CoachesModal
                coach={selectedCoach!}
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
            />
        </div>
    );
};

function cutTextByWord(text: string, maxLength: number) {
    if (text.length <= maxLength) return text;
    let lastSpace = text.substring(0, maxLength).lastIndexOf(' ');
    if (lastSpace === -1) return text.substring(0, maxLength);
    return text.substring(0, lastSpace) + '...';
}

register(Coaches, 'sof-coaches', ['dataCoaches']);
