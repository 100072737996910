import {h, FunctionComponent} from 'preact';
import {useEffect, useRef, useState} from 'preact/hooks';
import styles from './SubscriptionForm.module.sass';
import {closeAndDispatchModal} from "../utils";

interface FormState {
    fullName: string;
    email: string;
}

interface FormErrors {
    fullName: string | null;
    email: string | null;
}

const SubscriptionForm: FunctionComponent = () => {
    const [formState, setFormState] = useState<FormState>({
        fullName: '',
        email: '',
    });

    const [formErrors, setFormErrors] = useState<FormErrors>({
        fullName: null,
        email: null,
    });

    const fullNameRef = useRef<HTMLInputElement>(null);

    useEffect(() => fullNameRef.current?.focus(), []);

    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    // Handle input change
    const handleChange = (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
        const {name, value} = e.currentTarget;
        setFormState(prevState => ({...prevState, [name]: value}));
        // Clear error for a field when it's being edited
        setFormErrors(prevErrors => ({...prevErrors, [name]: null}));
    };

    // Validate form fields
    const validateForm = () => {
        const errors: FormErrors = {
            fullName: formState.fullName ? null : 'First name is required',
            email: isValidEmail(formState.email) ? null : 'Email is invalid',
        };
        setFormErrors(errors);
        // Check if any errors exist
        return !Object.values(errors).some(error => error !== null);
    };

    const handleSubmit = (e: Event) => {
        e.preventDefault();

        if (validateForm()) {
            // Assume formState contains fullName and email, and you've split them
            const [firstName, ...lastNameParts] = formState.fullName.split(' ');
            const lastName = lastNameParts.join(' ');

            // Create a form element
            const form = document.createElement('form');
            form.action = 'https://squashonfire.us14.list-manage.com/subscribe/post?u=23c345c52dd25949c660ac5b0&id=89276010da';
            form.method = 'POST';
            form.target = '_blank'; // Open in new tab/window

            // Add necessary input elements to the form
            const inputs = [
                {name: 'FNAME', value: firstName},
                {name: 'LNAME', value: lastName},
                {name: 'EMAIL', value: formState.email}
            ];

            inputs.forEach(({name, value}) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = name;
                input.value = value;
                form.appendChild(input);
            });

            // Append the form to the body and submit it
            document.body.appendChild(form);
            form.submit();

            document.body.removeChild(form);

            closeAndDispatchModal('subscriptionForm');
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.subscriptionForm}>
            <h2 className="section-header">Signup To Newsletter</h2>
            <div className="form-fields">
                <div className="form-group">
                    <label htmlFor="fullName" className="form-label">Full Name</label>
                    <input
                        ref={fullNameRef}
                        type="text"
                        id="fullName"
                        name="fullName"
                        className="form-input"
                        value={formState.fullName}
                        onChange={handleChange}
                    />
                    {formErrors.fullName && <div className="error-message">{formErrors.fullName}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-input"
                        value={formState.email}
                        onChange={handleChange}
                    />
                    {formErrors.email && <div className="error-message">{formErrors.email}</div>}
                </div>
            </div>
            <button type="submit" className={styles.button + ' button-black'}>Subscribe</button>
        </form>
    );
};

export default SubscriptionForm;
