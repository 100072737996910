import { FunctionComponent, h } from 'preact';
import styles from './Table.module.sass';

interface PricingTableProps {
    data: {
        headers: string[];
        pricingByType: { type: string; caption: string; prices: string[] }[];
        remark?: string;
    };
}

const Table: FunctionComponent<PricingTableProps> = ({ data }) => {
    const { headers, pricingByType, remark } = data;

    return (
        <div>
            <div className={`${styles.pricing} ${styles.desktop}`}>
                <table className={styles.table}>
                    <thead>
                        {headers && (
                            <tr className={styles.headers + ' ' + styles.row}>
                                <td></td>
                                {headers.map((header, headerIndex) => (
                                <td key={headerIndex} className={styles.cell}>{header}</td>
                                ))}
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {pricingByType.map((type, typeIndex) => (
                            <tr key={typeIndex} className={styles.row}>
                                <td className={styles.cell + ' ' + styles.pricingType}>
                                    {type.type}
                                    <div className={styles.caption}>{type.caption}</div>
                                </td>
                                {type.prices.map((price, priceIndex) => (
                                    <td key={priceIndex} className={styles.cell}>{price}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {remark && <div className={styles.remark}>{remark}</div>}
            </div>
            <div className={`${styles.pricing} ${styles.mobile}`}>
                {headers && (
                    <div>
                        {headers.map((header, headerIndex) => (
                        <div>
                        <table className={styles.table}>
                            <thead>
                            <tr className={styles.headers + ' ' + styles.row}>
                                <td key={headerIndex} colSpan={10} className={styles.cell}>{header}</td>
                            </tr>
                            </thead>
                            <tbody>
                            {pricingByType.map((type, typeIndex) => (
                                <tr key={typeIndex} className={styles.row}>
                                    <td className={styles.cell + ' ' + styles.pricingType}>
                                        {type.type}
                                        <div className={styles.caption}>{type.caption}</div>
                                    </td>

                                        <td  className={styles.cell}>{type.prices[headerIndex]}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {remark && <div className={styles.remark}>{remark}</div>}
                        </div>

                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Table;
