import { FunctionComponent, h } from 'preact';
import styles from './Team.module.sass';
import register from "preact-custom-element";
import { useEffect, useState } from "preact/hooks";
import { Person } from "../CommonTypes";
import { transformImagePath } from "../utils";
import CoachesModal from '../coachesModal/CoachesModal';

interface CategorizedPeople {
    [key: string]: Person[];
}

const categorizePeople = (people: Person[]): CategorizedPeople => {
    const categories: { [key: number]: string } = {
        1: 'Full Time Coaches',
        2: 'Part Time Coaches',
        3: 'Administrative Team',
    };

    const categorizedPeople = people.reduce((acc: CategorizedPeople, person: Person) => {
        const category = categories[person.category];
        if (category) {
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(person);
        }
        return acc;
    }, {});

    // Sort each category by the 'order' field in ascending order
    Object.keys(categorizedPeople).forEach(category => {
        categorizedPeople[category].sort((a, b) => a.order - b.order);
    });

    return categorizedPeople;
};

const Team: FunctionComponent = () => {
    const [people, setPeople] = useState<Person[]>([]);
    const [categorizedPeople, setCategorizedPeople] = useState<CategorizedPeople>({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);

    useEffect(() => {
        fetch("/data/people.json")
            .then(res => res.json())
            .then((data: Person[]) => {
                setPeople(data);
                setCategorizedPeople(categorizePeople(data));
            });
    }, []);

    const showPersonDetails = (person: Person) => {
        setSelectedPerson(person);
        setIsModalVisible(true);
    };

    const orderedCategories = ['Full Time Coaches', 'Part Time Coaches', 'Administrative Team'];

    return (
        <div className={styles.teamContainer}>
            {Object.keys(categorizedPeople).length > 0 ? (
                orderedCategories.map(category => (
                    categorizedPeople[category] && (
                        <div key={category}>
                            <h2>{category}</h2>
                            <div className={styles.teamGrid}>
                                {categorizedPeople[category].map(person => (
                                    <div
                                        key={person.id}
                                        className={styles.personCard}
                                        style={{ backgroundImage: `url(${transformImagePath(person.photo, 'preview/{filename}-gray')})` }}
                                        onClick={() => showPersonDetails(person)}
                                    >
                                        <h3 className={styles.personName}>{person.fullname}</h3>
                                        <h4 className={styles.personTitle}>{person.title}</h4>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                ))
            ) : (
                <p>Loading team members...</p>
            )}
            {selectedPerson && (
                <CoachesModal
                    coach={selectedPerson}
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
        </div>
    );
}

register(Team, 'sof-team');
