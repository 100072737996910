import { h } from 'preact';
import styles from "./CalendarMobile.module.sass";
import CalendarGridItemMobile from "./CalendarGridItemMobile";
import { CalendarDay, CalendarEvent } from "./Types";
import {isSameDay} from "date-fns";

interface Props {
    currentWeek: CalendarDay[];
    eventsLoaded: boolean;
    events: CalendarEvent[];
    currentDayIndex: number;
}

export const CalendarMobile = ({ currentWeek, eventsLoaded, events, currentDayIndex }: Props) => {
    return (
        <div className={styles.calendarGrid}>
            {eventsLoaded && currentWeek.map((item, index) => {
                const dayEvents = events.filter(event => isSameDay(event.start_date, item.date));
                return (
                    <CalendarGridItemMobile
                        key={item.day}
                        day={item}
                        dayEvents={dayEvents}
                        isCurrentDay={index === currentDayIndex}
                    />
                );
            })}
        </div>
    );

};
