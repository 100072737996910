import { FunctionComponent, h } from 'preact';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import register from "preact-custom-element";
import { useEffect, useState } from "preact/hooks";
import { transformImagePath } from "../utils";
import styles from './Gallery.module.sass';
import Modal from "../modal/Modal";
import {Navigation, Pagination, Thumbs} from "swiper/modules";
import {de} from "date-fns/locale/de";

SwiperCore.use([Navigation, Pagination, Thumbs]);

type Image = {
    title: string;
    image: string;
}

type ImageGalleryProps = {
    dataSource: string; // URL for the data source
};

const ImageGallery: FunctionComponent<ImageGalleryProps> = ({ dataSource }) => {
    const [images, setImages] = useState<Image[]>([]);
    const [selectedSlideIndex, setSelectedSlideIndex] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    useEffect(() => {
        fetch(dataSource).then(res => res.json().then((data: Image[]) => {
            preloadImages(data);
            setImages(data);
        }));
    }, [dataSource]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                handlePrevImage();
            } else if (e.key === 'ArrowRight') {
                handleNextImage();
            }
        };

        if (isModalVisible) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalVisible, selectedSlideIndex, images.length]);

    const handleSlideClick = (index: number) => {
        setSelectedSlideIndex(index);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const handlePrevImage = () => {
        setSelectedSlideIndex(prevIndex => (prevIndex === 0 || prevIndex === null) ? images.length - 1 : prevIndex - 1);
    };

    const handleNextImage = () => {
        setSelectedSlideIndex(prevIndex => (prevIndex === images.length - 1 || prevIndex === null) ? 0 : prevIndex + 1);
    };

    const preloadImages = (images: Image[]) => {
        images.forEach(image => {
            const img = new Image();
            img.src = transformImagePath(image.image, 'optimized/{filename}');
        });
    };

    return (
        <div>
            <div className={styles.thumbnailRow} style="position: relative">
                <Swiper
                    breakpoints={{
                        580: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 5,
                        },
                    }}
                    spaceBetween={30}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    navigation={{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}}>
                    {images.map((image, index) => (
                        <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
                            <div className={styles.thumbnail} style={{
                                backgroundImage: `url(${transformImagePath(image.image, 'thumbnails/{filename}')})`,
                                opacity: selectedSlideIndex === index ? 0.7 : 1
                            }}></div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={`${styles.swiperButtonPrev} swiper-button-prev`}></div>
                <div className={`${styles.swiperButtonNext} swiper-button-next`}></div>
            </div>
            {isModalVisible && selectedSlideIndex !== null && (
                <Modal onClose={closeModal}>
                    <div className={styles.modalContent}>
                        <img
                            className={styles.modalImage}
                            src={transformImagePath(images[selectedSlideIndex].image, 'optimized/{filename}')}
                            alt={images[selectedSlideIndex].title}
                        />
                        <div className='aL' onClick={handlePrevImage}></div>
                        <div className='aR' onClick={handleNextImage}></div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

register(ImageGallery, 'sof-gallery');
